<template>
    <div id="points">
        <h2>かたつむり大作戦 3箇条</h2>
        <hr>
        <img src="../assets/img/points.svg" alt="・運転手は無理をしない。・同乗者は無理をさせない。・後部座席もシートベルトを締める。" />
        <p class="center"><strong>愛するクラブの勝利を願うように、あなたが無事に帰ることを願う人がいます。<br> サッカーを愛する人すべてが安全に、観戦出来るようにかたつむり大作戦！にご参加ください。</strong></p>
    </div>
</template>

<script>
    export default {
        name: 'Points'
    }
</script>

<style>
    #points img {
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0
    }
</style>