<template>
    <footer class="center">
        <a href="https://www.sanga-saporen.net/" target="_blank">© 2015 Kyoto Sanga F.C. Supporter Confederation </a>
    </footer>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>

<style>
    footer {
        margin-top: 1em
    }

    @media screen and (max-width: 399px) {
        footer {
            font-size: 12px
        }
    }
</style>