<template>
    <div id="effect">
        <h2>趣旨</h2>
        <hr>
        <p>我々サポーターは、自チームのホームスタジアムでの観戦、応援のみならず、アウェイスタジアムへも足を運び、その交通手段として公共交通機関以外にも自家用車等で遠征することが多くある中では、交通事故等に巻き込まれる危険も大きいと考えます。</p>
        <p>新聞やニュースでも大きな交通事故が取り上げられる昨今、悲しい事故で大切な仲間を喪わずにすむように、今一度、交通安全、スタジアムでの安全を心掛け、互いに声を掛け合い意識を高めることが重要だと感じています。</p>
        <p>そのような中で、我々京都サンガF.C.サポーターは、遠征等での交通安全、スタジアムでの安全を強く意識し、道中無事に過ごせる願いを込めたステッカーを作成、配布することで、交通安全の啓蒙活動をして行きたいと思います。</p>
        <p>かたつむり大作戦！はゆっくり歩く“かたつむり”のように安全第一にサッカー観戦を楽しむ活動です。</p>
    </div>
</template>

<script>
    export default {
        name: 'Effect'
    }
</script>

<style>
    @media screen and (max-width:700px) {
        #effect {
            margin-top: 60px
        }
    }

    @media screen and (max-width:339px) {
        #effect {
            margin-top: 90px
        }
    }
</style>