<template>
    <div id="contact">
        <h2>問い合わせ先</h2>
        <hr>
        <p>京都サンガF.C.サポーター連合会 <a href="mailto:webmaster@sanga-saporen.net">webmaster@sanga-saporen.net</a></p>
    </div>
</template>

<script>
    export default {
        name: 'Contact'
    }
</script>

